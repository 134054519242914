.überschrift-wrapper{
    text-align: center;
    padding: 20px;
    margin: 50px 0 20px 0;
    display: flex;
    justify-content: center;
    color: var(--svss-blue);
    width: 100%;
    position: relative;

}

.überschrift-wrapper .überschrift__text{
    text-transform: uppercase;
    font-family: SourceSansPro-Bold;
    padding: 20px;
    background-color: var(--secondary-color);
    z-index: 1;
}

.überschrift-wrapper::before{
    content: "";
    position: absolute;
    width: 94%;
    height: 3px;
    top: 50%;
    right: 3%;
    background: var(--svss-blue);
}

/* .überschrift-wrapper::after{
    content: "";
    position: absolute;
    width: 35%;
    height: 3px;
    top: 50%;
    left: 3%;
    background: var(--svss-blue);
} */