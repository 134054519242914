@import url("../Layout/index.css");


/* .player-card {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1000px;
    background: red;
} */

/* .player-grid{
    display: flex;
    flex-wrap: wrap;
    justify-content:space-around;
    width: 100%;
} */

.player-grid {
    width: 80%;
    margin: 0 auto;
    display: grid;
    row-gap: 2rem;
    column-gap: 2rem;
    justify-items: center;
    align-items: center;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

/* .player-card .description {
    width: 100%;
    margin-top: 0;
    margin-left: 1rem;
    margin-bottom: 3rem;
} */

.player-card .card {
    color: inherit;
    cursor: pointer;
    width: 300px;
    height: 400px;
    perspective: 1000px;
    margin: 1rem;
    position: relative;
    filter: drop-shadow(0 0 5px black);
    transition: var(--mainTransition);
}

.player-card .front, .back {
    display: flex;
    border-radius: 6px;
    background-position: center;
    background-size: cover;
    text-align: center;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: ease-in-out 600ms;
}
.player-card .front {
    background-size: cover;
    font-size: 1.618rem;
    font-weight: 600;
    color: var(--secondary-color);
    overflow: hidden;
}
.player-card .front img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.player-card .front:before {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(135deg, #1a9be6, #1a57e6);
    opacity: 0.25;
    z-index: -1;
}
.player-card .card:hover .front {
    transform: rotateY(180deg);
}
.player-card .card:nth-child(even):hover .front {
    transform: rotateY(-180deg);
}
.player-card .back {
    background: var(--white);
    transform: rotateY(-180deg);
    padding: 0 2em;
}
.player-card .back .button {
    background: linear-gradient(135deg, #1a9be6, #1a57e6);
}
.player-card .back .button:before {
    box-shadow: var(--main-box-shadow);
    background-color: rgba(26, 87, 230, 0.25);
}
.player-card .card:hover .back {
    transform: rotateY(0deg);
}
.player-card .card:nth-child(even) .back {
    transform: rotateY(180deg);
}
.player-card .card:nth-child(even) .back .button {
    background: linear-gradient(135deg, #e61a80, #e61a3c);
}
.player-card .card:nth-child(even) .back .button:before {
    box-shadow: var(--main-box-shadow);
    background-color: rgba(230, 26, 60, 0.25);
}
.player-card .card:nth-child(even):hover .back {
    transform: rotateY(0deg);
}
/* .player-card .button {
    transform: translateZ(40px);
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    font-weight: bold;
    color:var(--white);
    padding: 0.5em 1em;
    border-radius: 100px;
    font: inherit;
    border: none;
    position: relative;
    transform-style: preserve-3d;
    transition: 300ms ease;
} */
.player-card .button:before {
    transition: 300ms ease;
    position: absolute;
    display: block;
    content: '';
    transform: translateZ(-40px);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    height: calc(100% - 20px);
    width: calc(100% - 20px);
    border-radius: 100px;
    left: 10px;
    top: 16px;
}
.player-card .button:hover {
    transform: translateZ(55px);
}
.player-card .button:hover:before {
    transform: translateZ(-55px);
}
.player-card .button:active {
    transform: translateZ(20px);
}
.player-card .button:active:before {
    transform: translateZ(-20px);
    top: 12px;
}


@media only screen and (max-width: 1200px){
    .player-grid {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        row-gap: 1.5rem;
        column-gap: 1.5rem;
      }

    .player-card .card {
        width: 200px;
        height: 270px;
    }

    .player-card .back {
        padding: 0 1em;
    }

    .player-card p{
        font-size: 0.7rem;
    }
    
    .player-card span{
        line-height: 0.9rem;
    }
}

@media only screen and (max-width: 768px){  
    .player-grid {
        grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
        row-gap: .8rem;
        column-gap: .8rem;
      }

    .player-card .card {
        width: 130px;
        height: 180px;
    }

    .player-card .back {
        padding: 0 .5em;
    } 
    
}
  