@import "../Layout/index.css";


.single-beitrag.text-wrapper{
    padding: 5%;
    color: var(--tertiary-color);
}

.single-beitrag.text-wrapper .inner-text-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;
    padding: 30px;
    border-top: 1px solid var(--tertiary-color);
    /* background-color: red; */
}

.beitrag-info.single{
    max-width: 500px;
    display: inline-block;
    width: 100%;
}

.beitrag-info__inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.beitrag-info__inner_ü{
    text-transform: uppercase;
    font-weight: 800;
}

.beitrag-link{
    color: var(--svss-blue);
}


@media only screen and (max-width: 768px){
    .single-beitrag.text-wrapper .inner-text-wrapper{
        flex-direction: column;
    }

    .beitrag-info__inner{
        flex-direction: column;
    }

    .beitrag-info__inner_ü{
        margin-top: 10px;
    }

    .single-beitrag.text-wrapper .btn-primary{
        margin-top: 40px;
    }
}