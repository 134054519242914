@import url("../../../Layout/index.css");


.nav.nav-right,
.nav.nav-left{
    display:flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;

    height: calc(100% - 80px);
    width: 320px;
    position: absolute;
    top: 80px;
    z-index: 1;
    background-color: var(--svss-blue); 
    opacity: .9;
    transition: var(--mainTransition);
}

.nav.nav-central{
    display:none;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    /* height: calc(1vh- 80px); */
    height: 100%;
    width: 100%;
    top: 0;
    position: fixed;
    text-align: center;
    z-index: 100;
    background-color: var(--svss-blue); 
    transition: var(--mainTransition);
}

.nav.nav-left{
    left: 0;
}

.nav.nav-right{
    right: 0px;
    text-align: right;
}

.nav.nav-left.isnt-active{
    transform: translate(-100%);
}

.nav.nav-right.isnt-active{
    transform: translate(100%);
}

.nav.nav-central.isnt-active{
    transform: translate( 0, -100%);
    /* scale: 0; */
    opacity: 0;
    /* border-radius: 50%; */
}

.nav .nav-text{
    text-transform: uppercase;
    font-weight: 1000;
    color: var(--white);
    font-size: 2rem;
}

.nav .nav-text:hover{
    transform: scale(1.1);
}

.nav .nav-text li:hover{
    transform: scale(1.1);   
}

.nav li{
    margin: auto 0;
}

.nav .nav-text-wrapper{
    padding: 5%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}



@media only screen and (max-width: 768px){
   
    .nav .nav-text-wrapper{
        align-items: center;
        display: flex;
    }

    .nav.nav-right,
    .nav.nav-left{
        display: none;
    }

    .nav.nav-central{
        display:flex;
    }

    .nav .nav-text{
        font-size: 1rem;
        padding: 0;
        margin: 0;
    }

    .nav h2.nav-text{
        font-size: 1.5rem;
    }

    .nav li{
        margin: auto 0;
    }
}