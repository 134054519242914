:root{
    --svss-blue: #272d61;
    --secondary-color: #ffffff;
    --tertiary-color: black;
    --standard-grey: #969696;
    --white: #ffffff;

    
    --spacing: 0.1rem;
    --main-border-radius: 10px;
    --main-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);

    --mainTransition: all 0.3s linear;
  }