@import "../Layout/index.css";

.training.wrapper{
}


.termin__inner{
    border-radius: var(--main-border-radius);
    background-color: var(--white);
    box-shadow: var(--main-box-shadow);
    margin: 0 20px 20px 20px;
    padding: 10px 20px;
    /* transform: scale(1.2); */
}

.termin__inner .tag{
    font-weight: 800;
}

.kommentar.wrapper{
    flex-direction: column;
    padding: 10px;
    color: var(--tertiary-color);
    /* border-top: 1px solid black; */
}

.kommentar__inner{
    display: flex;
    /* flex-direction: column; */
    padding: 5px;
}

