@import url("../Layout/index.css");

.suche-wrapper{
    margin: 0 auto 50px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.suche-wrapper form{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.suche-wrapper input{
    border-radius: var(--main-border-radius);
    /* line-height: 1rem; */
    width: 350px;
    height: 40px;
    padding: 0 0 0 10px;
    box-shadow: var(--main-box-shadow);

    border: 0px solid black;
    /* box-shadow: 0px 0px 5px 0px black; */
}
.suche-wrapper input:hover{
    transform: scale(1.03);
}

.suche-wrapper input,
.suche-wrapper input:focus{
    outline: none;
}

.suche-wrapper .btn-1{
    cursor: pointer;
}