@import url("../../Layout/index.css");


.footer{
    width: 100%;
    height: auto;
    color: var(--white);
    background-color: var(--svss-blue);
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 100px;
}

.footer .svss-logo-wrapper{
    cursor: pointer;
    position: absolute;
    width: 90px;
    height: 90px;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -45px;
    transition: all .5s ease;
    z-index: 1;
    border-radius: 50%;
    background-color: var(--white);
}

.svss-logo-wrapper:hover{
    transform: scale(1.05);
}

.footer .svss-logo{
    width: 100%;
    height: 100%;
    transform: scale(0.75);
    object-fit: contain;
}

.footer .social-media{
    margin: 50px 0 0 0;
}

.footer .social-media__icons{
    display: flex;
    justify-content: space-around;
}

.footer .nav-links{
    width: 65%;
    display: flex;
    justify-content: space-around;
}

.footer .nav-links__inner{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.footer .nav-links__inner__inner{
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.footer .nav-links--main::after{
    content:'';
    position:absolute;
    bottom: 15%;
    width: 1px;
    height: 25%;
    background: var(--white);
}

.footer .nav-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 0 30px 0;
}

.footer .nav-text{
    margin: 10px;
    color: var(--white);
    transition: var(--mainTransition);
}

.footer .nav-text:hover{
    transform: scale(1.1);
}

/* .footer .nav-links .überschrift {
    margin: 20px;
    padding: 10px 20px;
    border-bottom: 1px solid var(--secondary-color)
} */

.footer .nav-wrapper--sonstige{
    border-top: 1px solid var(--white);
    display: flex;
    justify-content: space-around;
    width: 70%;
}

.footer .überschrift{
    margin: 40px 0 20px 0;
}

.footer .impressum-text{
    margin: 50px 0 15px 0;
}

@media only screen and (max-width: 1200px){
    .footer .nav-links--main::after{
        height: 20%;
    }
}

@media only screen and (max-width: 768px){
    .footer .nav-links{
        flex-direction: column;
        margin: 0 0 50px 0;
    }

    .footer .nav-links__inner__inner{
        flex-direction: column;
    }

    .footer .nav-links--main::after{
        display: none;
    }

    .footer .nav-wrapper{
        margin: 0;
    }

    .footer .überschrift{
        margin: 40px 0 10px 0;
    }

    .footer .nav-text{
        margin: 5px;
    }
  }