@import url("../Layout/index.css");

.btn.downloads {
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  
  min-width: 350px;
  width: 50%;

  text-transform: uppercase;
  outline: none;
  overflow: hidden;
  position: relative;
  color: var(--secondary-color);

  background-color: var(--svss-blue);
  padding: 5px 50px;
  margin: 10px;
  border-radius: var(--main-border-radius);
  box-shadow: var(--main-box-shadow);
  transition: var(--mainTransition);
}

.btn.downloads:hover{
  transform: scale(1.01)
}

.btn.downloads span {
  position: relative; 
  z-index: 1;
}

/* .btn.downloads:hover span,
.btn.downloads:hover .icon{
  color: var(--svss-blue);
} */

/* .btn.downloads:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 1000%;
  width: 100%;
  background: var(--secondary-color);

  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  -webkit-transform: translateX(-98%) translateY(-25%) rotate(-45deg);
  transform: translateX(-98%) translateY(-25%) rotate(-45deg);
}

.btn.downloads:hover:after {
  -webkit-transform: translateX(-9%) translateY(-25%) rotate(-45deg);
  transform: translateX(-9%) translateY(-25%) rotate(-45deg);
} */

.downloads a{
  z-index: 1;
}
.downloads .icon {
  cursor: pointer;
  font-size: 2rem;
  transition: var(--mainTransition);
  color: var(--secondary-color);
}

.downloads .icon:hover {
  transform: scale(1.3);
}
