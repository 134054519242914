.slider-wrapper{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -10;
}

.navbar .slider-wrapper{
  height: calc(100% - 80px);
}

.slider-wrapper.activeSlide {
    transform: translateX(0);
  }

.slider-wrapper.lastSlide {
    /* transform: translateX(-100%); */
    opacity: 0;
  }

.slider-wrapper.nextSlide {
    /* transform: translateX(100%); */
    opacity: 0;
  }

.slider-wrapper.otherSlide {
    /* transform: translateX(100%); */
    opacity: 0;
}

.slider-image{
    height: 100%;
    width: 100%;
    object-fit: contain;
    /* position: absolute; */
    top: 0;
    left: 0;
    z-index: -1;
    transition: .5s all ease;
}

.slider-image-background{
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  transition: .5s all ease;
  -webkit-filter: brightness(50%) ; /* Safari 6.0 - 9.0 */
  filter: brightness(50%);
  /* filter:saturate(50%) */
  /* opacity: .7; */
}

.navbar .slider-image-title{
  position: absolute;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  bottom: 0px;

  background-color: rgba(0, 0, 0, 0.3);
  color: var(--white);
  padding: 5px;

  z-index: 10;
}

  .prev,
  .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: var(--secondary-color);
    background: none;
    border: none;
    width: 1.25rem;
    height: 1.25rem;
    display: grid;
    place-items: center;

    font-size: 1rem;
    cursor: pointer;
    transition: var(--mainTransition);
  }
  .prev:hover,
  .next:hover {
    color: var(--svss-blue);
  }
  .prev:focus,
  .next:focus {
    outline: none;
  }
  .prev {
    left: 0;
  }
  .next {
    right: 0;
  }

  @media only screen and (min-width: 768px) {
    .prev,
    .next {
      width: 2rem;
      height: 2rem;
      font-size: 1.5rem;
    }
  }
