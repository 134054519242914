
@import url("../../Layout/index.css");

.mehr-laden{
    max-width: 50%;
    margin: 40px auto;
    text-align: center;
    color: var(--svss-blue);
    cursor: pointer;
    transition: var(--mainTransition);
  }

.mehr-laden:hover{
    transform: scale(1.1);
  }

.mehr-laden .icon{
    font-size: 2rem;
}

.mehr-laden p{
    margin: 0;
}