:root{
    --svss-blue: #272d61;
    --secondary-color: #ffffff;
    --tertiary-color: black;
    --standard-grey: #969696;
    --white: #ffffff;

    
    --spacing: 0.1rem;
    --main-border-radius: 10px;
    --main-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);

    --mainTransition: all 0.3s linear;
  }
.slider-wrapper{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -10;
}

.navbar .slider-wrapper{
  height: calc(100% - 80px);
}

.slider-wrapper.activeSlide {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

.slider-wrapper.lastSlide {
    /* transform: translateX(-100%); */
    opacity: 0;
  }

.slider-wrapper.nextSlide {
    /* transform: translateX(100%); */
    opacity: 0;
  }

.slider-wrapper.otherSlide {
    /* transform: translateX(100%); */
    opacity: 0;
}

.slider-image{
    height: 100%;
    width: 100%;
    object-fit: contain;
    /* position: absolute; */
    top: 0;
    left: 0;
    z-index: -1;
    transition: .5s all ease;
}

.slider-image-background{
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  transition: .5s all ease;
  -webkit-filter: brightness(50%) ; /* Safari 6.0 - 9.0 */
  filter: brightness(50%);
  /* filter:saturate(50%) */
  /* opacity: .7; */
}

.navbar .slider-image-title{
  position: absolute;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  bottom: 0px;

  background-color: rgba(0, 0, 0, 0.3);
  color: var(--white);
  padding: 5px;

  z-index: 10;
}

  .prev,
  .next {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    color: var(--secondary-color);
    background: none;
    border: none;
    width: 1.25rem;
    height: 1.25rem;
    display: grid;
    place-items: center;

    font-size: 1rem;
    cursor: pointer;
    transition: var(--mainTransition);
  }
  .prev:hover,
  .next:hover {
    color: var(--svss-blue);
  }
  .prev:focus,
  .next:focus {
    outline: none;
  }
  .prev {
    left: 0;
  }
  .next {
    right: 0;
  }

  @media only screen and (min-width: 768px) {
    .prev,
    .next {
      width: 2rem;
      height: 2rem;
      font-size: 1.5rem;
    }
  }

*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* color: var(--tertiary-color); */
  font-family: SourceSansPro-ExtraLight; 
  font-size: 1em;
  transition: var(--mainTransition);
  box-sizing: border-box;
}


body{
  background-color: var(--secondary-color);
  width: 100%;
}

.wrapper{
  display: flex;
  justify-content: center;
  align-items:center;
  flex-wrap: wrap;

  height: auto;
  width: 100%;
  margin: 0 auto;
  padding: 0 10%;
  text-align: center;
}


@font-face{
  font-family: SourceSansPro-Bold;
  src: url(/static/media/SourceSansPro-Bold.1a2a157b.otf) format('opentype');
}

@font-face{
  font-family: Gotham;
  src: url(/static/media/Gotham.c9f7f5fb.otf);
}

@font-face{
  font-family: SourceSansPro-ExtraLight;
  src: url(/static/media/SourceSansPro-ExtraLight.d4882f17.otf);
}

.überschrift{
  font-family: SourceSansPro-Bold;
  text-transform: uppercase;
}

ul {
  list-style-type: none;
}
a,
a:link,
a:visited {
  text-decoration: none;
  /* color: var(--secondary-color); */
}

h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  /* text-transform: capitalize; */
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-5);
}

.content-wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.maps{
  width: 100%;
  height: 100%;
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

.maps-wrapper{
  margin: 2%;
  width: 45%;
  height: 400px;
  overflow: hidden;
  border-radius: var(--main-border-radius);
  box-shadow: var(--main-box-shadow);
}

/* Scrollbar -------------*/
::-webkit-scrollbar {
  width: 15px;
}
::-webkit-scrollbar-track {
  background: var(--secondary-color); 
}

::-webkit-scrollbar-thumb {
  background: var(--svss-blue); 
  border-radius: 10px;
}
/* Scrollbar end --------------*/


/* Buttons ------------ */
.btn-primary {
  text-transform: uppercase;
  letter-spacing: var(--mainSpacing);
  color: var(--svss-blue);
  border: 2px solid var(--svss-blue);
  padding: 0.2rem 0.8rem;
  transition: var(--mainTransition);
  cursor: pointer;
  font-size: 0.8rem;
  background: transparent;
  border-radius: var(--main-border-radius);
}

.btn-primary {
  background: var(--svss-blue);
  color: var(--white);
}

.btn-primary:hover {
  background: var(--secondary-color);
  color: var(--svss-blue);
}

.btn-block {
  width: 100%;
  display: block;
  margin: 0 auto;
  box-shadow: var(--lightShadow);
  text-align: center;
}
.btn-details {
  padding: 0.25rem 0.4rem;
}
.btn-details:hover {
  background: var(--primaryLightColor);
  border-color: var(--primaryLightColor);
}

.btn-1 {
  border: none;

  /* min-width: 350px; */
  text-align: left;

  text-transform: uppercase;
  outline: none;
  color: var(--secondary-color);

  background-color: var(--svss-blue);
  padding: 5px 20px;
  margin: 10px;
  border-radius: var(--main-border-radius);
  box-shadow: var(--main-box-shadow);
  transition: var(--mainTransition);
}

.btn-1:hover{
  -webkit-transform: scale(1.03);
          transform: scale(1.03)
}

.btn-1 span {
  position: relative; 
}

@media only screen and (min-width: 2500px){
  body{
    margin: 0 auto;
    display: block;
    width: 2500px;
    border: solid 4px var(--svss-blue);

  }
}

/* Buttons end ----------------*/

@media only screen and (max-width: 1200px){
  .maps-wrapper{
    width: 100%;
  }
}

@media only screen and (max-width: 768px){
  .btn-1 {
    min-width: 0px;
    width: 100%;
    padding: 5px;
  }

  h1,
  h2,
  h3,
  h4 {
    line-height: 1.00;
    margin-bottom: 0.5rem;
  }
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.3rem;
  }
  h3 {
    font-size: .9rem;
  }
  h4 {
    font-size: .6rem;
  }
  p {
    margin-bottom: 1.25rem;
    color: var(--clr-grey-5);
  }

  
}

.navbar{
    height: 100vh;
    position: relative;
    overflow: hidden;
    position:relative;
}

.navbar::after{
    width: 100%;
    height: 2px;
    background-color: var(--svss-blue);
    z-index:-100;
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
}

.navbar__leiste{
    height: 80px;
    width: 100%;
    background: var(--svss-blue);
}

.navbar .svss-logo-wrapper{
    cursor: pointer;
    position: absolute;
    width: 90px;
    height: 90px;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 35px;
    transition: all .5s ease;
    border-radius: 50%;
    background-color: var(--white);
}

.navbar .svss-logo-wrapper:hover{
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.navbar .svss-logo{
    width: 100%;
    height: 100%;
    -webkit-transform: scale(0.75);
            transform: scale(0.75);
    object-fit: contain;
}

.nav-alt-img-wrapper{
    width: 100%;
    height: 100%;
    border-bottom: 5px solid var(--svss-blue);
    z-index: -50;
}

.nav-alt-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hamburger-left{
    position: absolute;
    top: 28px;
    left: 28px;
}

.hamburger-right{
    position: absolute;
    top: 28px;
    right: 28px;
}

.page-name{
    color: var(--white);
    position: absolute;
    top: 20px;
    left: 100px;
    line-height: 100%;
    font-family: SourceSansPro-Bold;
    font-size: 2.5em;
    text-transform: uppercase;
}


.small-window{
    display:none;
    -webkit-transform: scale(.7);
            transform: scale(.7);
    z-index: 100;
  }

  @media (max-aspect-ratio: 3/4){
    .navbar{
        height: 50vh;
    }
  }

@media only screen and (max-width: 768px){

    .navbar .svss-logo-wrapper{
        top: -5px;
        left: auto;
        right: 0px;
        transition: all .5s ease;
        -webkit-transform: scale(.6);
                transform: scale(.6);
    }

    .navbar .svss-logo-wrapper:hover{
        -webkit-transform: scale(.7);
                transform: scale(.7);
    }

    .page-name{
        top: 29px;
        left: 70px;
        font-size: 1.5rem;
    }

    .normal-window{
        display:none;
      }

    .small-window{
        display:block;
    }
  }


.hamburger {
  /* display: inline-block; */
  cursor: pointer;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;}
  .hamburger:hover {
    opacity: 0.7; }
  .hamburger.is-active:hover {
    opacity: 0.7; }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: var(--white); }

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: var(--white);
    border-radius: 4px;
    position: absolute;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -10px; }
  .hamburger-inner::after {
    bottom: -10px; }
.hamburger--squeeze .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
    .hamburger--squeeze .hamburger-inner::before {
      transition: top 0.075s 0.12s ease, opacity 0.075s ease; }
    .hamburger--squeeze .hamburger-inner::after {
      transition: bottom 0.075s 0.12s ease, -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  
  .hamburger--squeeze.is-active .hamburger-inner {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    .hamburger--squeeze.is-active .hamburger-inner::before {
      top: 0;
      opacity: 0;
      transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
    .hamburger--squeeze.is-active .hamburger-inner::after {
      bottom: 0;
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
      transition: bottom 0.075s ease, -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }


    @media only screen and (max-width: 768px){

    }
.nav.nav-right,
.nav.nav-left{
    display:flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;

    height: calc(100% - 80px);
    width: 320px;
    position: absolute;
    top: 80px;
    z-index: 1;
    background-color: var(--svss-blue); 
    opacity: .9;
    transition: var(--mainTransition);
}

.nav.nav-central{
    display:none;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    /* height: calc(1vh- 80px); */
    height: 100%;
    width: 100%;
    top: 0;
    position: fixed;
    text-align: center;
    z-index: 100;
    background-color: var(--svss-blue); 
    transition: var(--mainTransition);
}

.nav.nav-left{
    left: 0;
}

.nav.nav-right{
    right: 0px;
    text-align: right;
}

.nav.nav-left.isnt-active{
    -webkit-transform: translate(-100%);
            transform: translate(-100%);
}

.nav.nav-right.isnt-active{
    -webkit-transform: translate(100%);
            transform: translate(100%);
}

.nav.nav-central.isnt-active{
    -webkit-transform: translate( 0, -100%);
            transform: translate( 0, -100%);
    /* scale: 0; */
    opacity: 0;
    /* border-radius: 50%; */
}

.nav .nav-text{
    text-transform: uppercase;
    font-weight: 1000;
    color: var(--white);
    font-size: 2rem;
}

.nav .nav-text:hover{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.nav .nav-text li:hover{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);   
}

.nav li{
    margin: auto 0;
}

.nav .nav-text-wrapper{
    padding: 5%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}



@media only screen and (max-width: 768px){
   
    .nav .nav-text-wrapper{
        align-items: center;
        display: flex;
    }

    .nav.nav-right,
    .nav.nav-left{
        display: none;
    }

    .nav.nav-central{
        display:flex;
    }

    .nav .nav-text{
        font-size: 1rem;
        padding: 0;
        margin: 0;
    }

    .nav h2.nav-text{
        font-size: 1.5rem;
    }

    .nav li{
        margin: auto 0;
    }
}
.loading-wrapper{
    justify-content: center;
    display: flex;
    align-items: center;

    min-width: 50px;
    min-height: 50px;
    height: 100%;
    width: 100%;
    padding: 25px 0;
    display: flex;
    justify-content: center;
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-roller div {
    -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    -webkit-transform-origin: 40px 40px;
            transform-origin: 40px 40px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: var(--svss-blue);
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    -webkit-animation-delay: -0.036s;
            animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    -webkit-animation-delay: -0.072s;
            animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    -webkit-animation-delay: -0.108s;
            animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    -webkit-animation-delay: -0.144s;
            animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    -webkit-animation-delay: -0.18s;
            animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    -webkit-animation-delay: -0.216s;
            animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    -webkit-animation-delay: -0.252s;
            animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    -webkit-animation-delay: -0.288s;
            animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @-webkit-keyframes lds-roller {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes lds-roller {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
.verein{
    width: 100%;
}

.verein .verein-grid{
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-template-rows: auto;
    grid-gap: 50px;
    gap: 50px;
    grid-template-areas: 
        "logo text"
        "logo website";
    align-items: center;
    /* justify-items: center; */
    width: 80%;
    margin: 0 auto;
}

.verein .logo{
    grid-area: logo;
    overflow:hidden;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--main-border-radius);
}

.verein .logo img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.verein .text{
    grid-area: text;
    width: 100%;
    text-transform: none;
    color: var(--tertiary-color);
}

.verein .website{
    grid-area: website;
    width: 100%;
}

.website:hover{
    -webkit-transform: scale(1.01);
            transform: scale(1.01)
  }

.verein .verein-wrapper{
    margin: 50px auto 0 auto;
    width: 90%;
    min-height: 300px;
    justify-content: space-around;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.verein .verein-wrapper-inner{
    overflow: hidden;
    border-radius: var(--main-border-radius);
    position: relative;
    width: 45%;
    height: 25vw;
    margin: 20px;
    box-shadow: var(--main-box-shadow);

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media only screen and (max-width: 1200px){
    .verein .verein-wrapper{
        display: flex;
        flex-direction: column;
    }
    
    .verein .verein-wrapper-inner{
        width: 100%;
    }
}

@media only screen and (max-width: 768px){
    .verein .verein-grid{
        grid-template-columns: 1fr;
        grid-template-rows: 150px auto auto;
        grid-gap: 10px;
        gap: 10px;
        grid-template-areas: 
            "logo"
            "text"
            "website";
        align-items: center;
        width: 90%;
        margin: 0 auto;
    }
    
    .verein .logo{
        grid-area:logo;
        /* background-color: red; */
    }

    .verein .logo img {
       height: 100%;
       width: 100%;
       object-fit: contain;
    }
    
    .verein .text{
        grid-area: text;

    }
    
    .verein .website{
        grid-area: website;
    }

    .verein .verein-wrapper-inner{
        height: 60vw;
        width: 95%;
    }
    
}


.überschrift-wrapper{
    text-align: center;
    padding: 20px;
    margin: 50px 0 20px 0;
    display: flex;
    justify-content: center;
    color: var(--svss-blue);
    width: 100%;
    position: relative;

}

.überschrift-wrapper .überschrift__text{
    text-transform: uppercase;
    font-family: SourceSansPro-Bold;
    padding: 20px;
    background-color: var(--secondary-color);
    z-index: 1;
}

.überschrift-wrapper::before{
    content: "";
    position: absolute;
    width: 94%;
    height: 3px;
    top: 50%;
    right: 3%;
    background: var(--svss-blue);
}

/* .überschrift-wrapper::after{
    content: "";
    position: absolute;
    width: 35%;
    height: 3px;
    top: 50%;
    left: 3%;
    background: var(--svss-blue);
} */
/* .beitrag{
        width: 100%;
        height: auto;
    } */

    .beitrag-wrapper{
        min-height: 400px;
        width: 100%;
        background: var(--white);
        box-shadow: var(--main-box-shadow);
        border-radius: var(--main-border-radius);
        transition: var(--mainTransition);
        /* overflow: hidden; */
    }

    .beitrag-wrapper:hover{
        -webkit-transform: scale(1.03);
                transform: scale(1.03);
    }

    .beitrag-wrapper .image-wrapper{
        position: relative;
        background: var(--svss-blue);
        background-position: center center;
        background-repeat: no-repeat;
        height: 300px;
        width: 100%;
        border-radius: var(--main-border-radius) var(--main-border-radius) 0 0;
        overflow: hidden;
    }

    .beitrag-wrapper .text-wrapper{
        padding: 1rem;
        width: 100%;
        border-radius: 0 0 var(--main-border-radius) var(--main-border-radius);
        color: black;
        position: relative;
    }

    .beitrag-wrapper .image-wrapper img{
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: var(--mainTransition);
    }

    .beitrag-wrapper .image-wrapper img:hover{
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }

    .beitrag-wrapper .text-wrapper .datum{
        margin: 0 0 3rem 0;
        font-weight: 800;
    }

    .beitrag-wrapper .beitrag-info.beitrag{
        width: 100%;
    }

    .beitrag-wrapper .titel{
        min-height: 4rem;
    }

    .beitrag-wrapper .text{
        min-height: 7rem;
    }


.dark-mode-button{
    display: flex;
    align-items: center;
    justify-content: center;

    width: 35px;
    height: 35px;
    border-radius: 50%;

    position: absolute;
    bottom: 20px;
    right: 20px;
    
    cursor: pointer;
    background-color: var(--secondary-color);
    transition: var(--mainTransition);
    text-transform: uppercase;
    font-weight: 1000;
    font-size: .8rem;
    box-shadow: var(--main-box-shadow);
}

.dark-mode-button:hover{
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.dark-mode-button.dunkel {
    background-color: var(--white);
 }



.dark-mode-button .dunkel,
.dark-mode-button .hell {
    color: var(--tertiary-color);
 }

 /* .dark-mode-button .hell{
    color: var(--teritary-color);
 } */
.sponsoren-logo{
    width: 100%;
    height: 100%;
    object-fit:contain;

}

.sponsoren-logo-wrapper:hover{
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.sponsoren-logo-wrapper{
    transition: all 1s ease;
    width: 100px;
    height: 70px;
    overflow: hidden;
    margin: 10px;
    background-color: #ffffff;
    vertical-align: center;
    border: solid 1px var(--secondary-color);
    border-radius: 5px;
}

.sponsoren-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;

    margin: 0 auto;
    height: auto;
    width: 80%;
}


/* @media only screen and (max-width: 1200px){
.sponsoren-wrapper{
    grid-template-columns: repeat(2, 50%);
    margin: 0 auto;
}
}

@media only screen and (max-width: 768px){
.sponsoren-wrapper{
    grid-template-columns: 100%;
    margin: 0 auto;
}
} */
.footer{
    width: 100%;
    height: auto;
    color: var(--white);
    background-color: var(--svss-blue);
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 100px;
}

.footer .svss-logo-wrapper{
    cursor: pointer;
    position: absolute;
    width: 90px;
    height: 90px;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -45px;
    transition: all .5s ease;
    z-index: 1;
    border-radius: 50%;
    background-color: var(--white);
}

.svss-logo-wrapper:hover{
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.footer .svss-logo{
    width: 100%;
    height: 100%;
    -webkit-transform: scale(0.75);
            transform: scale(0.75);
    object-fit: contain;
}

.footer .social-media{
    margin: 50px 0 0 0;
}

.footer .social-media__icons{
    display: flex;
    justify-content: space-around;
}

.footer .nav-links{
    width: 65%;
    display: flex;
    justify-content: space-around;
}

.footer .nav-links__inner{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.footer .nav-links__inner__inner{
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.footer .nav-links--main::after{
    content:'';
    position:absolute;
    bottom: 15%;
    width: 1px;
    height: 25%;
    background: var(--white);
}

.footer .nav-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 0 30px 0;
}

.footer .nav-text{
    margin: 10px;
    color: var(--white);
    transition: var(--mainTransition);
}

.footer .nav-text:hover{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

/* .footer .nav-links .überschrift {
    margin: 20px;
    padding: 10px 20px;
    border-bottom: 1px solid var(--secondary-color)
} */

.footer .nav-wrapper--sonstige{
    border-top: 1px solid var(--white);
    display: flex;
    justify-content: space-around;
    width: 70%;
}

.footer .überschrift{
    margin: 40px 0 20px 0;
}

.footer .impressum-text{
    margin: 50px 0 15px 0;
}

@media only screen and (max-width: 1200px){
    .footer .nav-links--main::after{
        height: 20%;
    }
}

@media only screen and (max-width: 768px){
    .footer .nav-links{
        flex-direction: column;
        margin: 0 0 50px 0;
    }

    .footer .nav-links__inner__inner{
        flex-direction: column;
    }

    .footer .nav-links--main::after{
        display: none;
    }

    .footer .nav-wrapper{
        margin: 0;
    }

    .footer .überschrift{
        margin: 40px 0 10px 0;
    }

    .footer .nav-text{
        margin: 5px;
    }
  }
.social-media-wrapper{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding: 50px;
    /* background-color: red; */
}

.social-media-wrapper__inner{
    padding: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: auto;
}

.social-media-link{
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--main-border-radius);
}

.social-media-link:hover{
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
}

.social-media-wrapper__inner__inner{
    border: none;
    overflow: hidden;
    border-radius: var(--main-border-radius);
    box-shadow: var(--main-box-shadow);
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--secondary-color);
    position: relative;
}

.facebook{
    width: 500px;
    height: 705px;
}

.snapwidget-widget{
    width: 500px;
    height: 705px;
}


@media only screen and (max-width: 768px){
    .social-media-wrapper__inner{
        width: 100%;
        overflow: hidden;
    }

    .facebook{
        width: 350px;
        height: 705px;
        display: none;
    }
    
    .snapwidget-widget{
        width: 350px;
        height: 705px;
        display: none;
    }
}
.archiv{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 100%;
}

.archiv-slider-image{
    height: 100%;
    width: 100%;
    object-fit: contain;
    background: var(--svss-blue);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: .5s all ease;
}

.archiv .archiv-wrapper{
    overflow: hidden;
    border-radius: var(--main-border-radius);
    position: relative;
    width: 90%;
    height: 50vw;
    box-shadow: var(--main-box-shadow);

    text-align: center;
    display: flex;
    justify-content: center;
}

.archiv-text{
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.archiv-text .lastSlide ,
.archiv-text .nextSlide {
    display: none;
  }

.activeSlide .slider-titel,
.activeslide .slider-description{
    text-align: center;
    color: var(--tertiary-color);
  }


/* .player-card {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1000px;
    background: red;
} */

/* .player-grid{
    display: flex;
    flex-wrap: wrap;
    justify-content:space-around;
    width: 100%;
} */

.player-grid {
    width: 80%;
    margin: 0 auto;
    display: grid;
    grid-row-gap: 2rem;
    row-gap: 2rem;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
    justify-items: center;
    align-items: center;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

/* .player-card .description {
    width: 100%;
    margin-top: 0;
    margin-left: 1rem;
    margin-bottom: 3rem;
} */

.player-card .card {
    color: inherit;
    cursor: pointer;
    width: 300px;
    height: 400px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    margin: 1rem;
    position: relative;
    -webkit-filter: drop-shadow(0 0 5px black);
            filter: drop-shadow(0 0 5px black);
    transition: var(--mainTransition);
}

.player-card .front, .back {
    display: flex;
    border-radius: 6px;
    background-position: center;
    background-size: cover;
    text-align: center;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    transition: ease-in-out 600ms;
}
.player-card .front {
    background-size: cover;
    font-size: 1.618rem;
    font-weight: 600;
    color: var(--secondary-color);
    overflow: hidden;
}
.player-card .front img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.player-card .front:before {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(135deg, #1a9be6, #1a57e6);
    opacity: 0.25;
    z-index: -1;
}
.player-card .card:hover .front {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}
.player-card .card:nth-child(even):hover .front {
    -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
}
.player-card .back {
    background: var(--white);
    -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
    padding: 0 2em;
}
.player-card .back .button {
    background: linear-gradient(135deg, #1a9be6, #1a57e6);
}
.player-card .back .button:before {
    box-shadow: var(--main-box-shadow);
    background-color: rgba(26, 87, 230, 0.25);
}
.player-card .card:hover .back {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
}
.player-card .card:nth-child(even) .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}
.player-card .card:nth-child(even) .back .button {
    background: linear-gradient(135deg, #e61a80, #e61a3c);
}
.player-card .card:nth-child(even) .back .button:before {
    box-shadow: var(--main-box-shadow);
    background-color: rgba(230, 26, 60, 0.25);
}
.player-card .card:nth-child(even):hover .back {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
}
/* .player-card .button {
    transform: translateZ(40px);
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    font-weight: bold;
    color:var(--white);
    padding: 0.5em 1em;
    border-radius: 100px;
    font: inherit;
    border: none;
    position: relative;
    transform-style: preserve-3d;
    transition: 300ms ease;
} */
.player-card .button:before {
    transition: 300ms ease;
    position: absolute;
    display: block;
    content: '';
    -webkit-transform: translateZ(-40px);
            transform: translateZ(-40px);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    height: calc(100% - 20px);
    width: calc(100% - 20px);
    border-radius: 100px;
    left: 10px;
    top: 16px;
}
.player-card .button:hover {
    -webkit-transform: translateZ(55px);
            transform: translateZ(55px);
}
.player-card .button:hover:before {
    -webkit-transform: translateZ(-55px);
            transform: translateZ(-55px);
}
.player-card .button:active {
    -webkit-transform: translateZ(20px);
            transform: translateZ(20px);
}
.player-card .button:active:before {
    -webkit-transform: translateZ(-20px);
            transform: translateZ(-20px);
    top: 12px;
}


@media only screen and (max-width: 1200px){
    .player-grid {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        grid-row-gap: 1.5rem;
        row-gap: 1.5rem;
        grid-column-gap: 1.5rem;
        -webkit-column-gap: 1.5rem;
                column-gap: 1.5rem;
      }

    .player-card .card {
        width: 200px;
        height: 270px;
    }

    .player-card .back {
        padding: 0 1em;
    }

    .player-card p{
        font-size: 0.7rem;
    }
    
    .player-card span{
        line-height: 0.9rem;
    }
}

@media only screen and (max-width: 768px){  
    .player-grid {
        grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
        grid-row-gap: .8rem;
        row-gap: .8rem;
        grid-column-gap: .8rem;
        -webkit-column-gap: .8rem;
                column-gap: .8rem;
      }

    .player-card .card {
        width: 130px;
        height: 180px;
    }

    .player-card .back {
        padding: 0 .5em;
    } 
    
}
  
.kontakt.wrapper{
    display: flex;
    justify-content: center;
    align-items:center;
    flex-wrap: wrap;

    height: auto;
    width: 100%;
    margin: 0 auto;
    padding: 0 10%;
    text-align: center;
}

.kontakt .person{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin: 30px;
    padding: 10px;
    min-width: 330px;
    min-height: 110px;

    background-color: var(--white);
    border-radius: var(--main-border-radius);
    box-shadow: var(--main-box-shadow);
}

.kontakt .funktion{
    margin: 0;
}

@media only screen and (max-width: 768px){
    .kontakt{
        flex-direction: column;
    }
}

@media only screen and (max-width: 500px){
    .kontakt .person{
        width: 100%;
        min-width: 200px;
        margin: 30px 0;
    }
}


.training.wrapper{
}


.termin__inner{
    border-radius: var(--main-border-radius);
    background-color: var(--white);
    box-shadow: var(--main-box-shadow);
    margin: 0 20px 20px 20px;
    padding: 10px 20px;
    /* transform: scale(1.2); */
}

.termin__inner .tag{
    font-weight: 800;
}

.kommentar.wrapper{
    flex-direction: column;
    padding: 10px;
    color: var(--tertiary-color);
    /* border-top: 1px solid black; */
}

.kommentar__inner{
    display: flex;
    /* flex-direction: column; */
    padding: 5px;
}


.jfg .text{
    color: var(--tertiary-color);
}
.logos.wrapper{
    padding: 5% 0;
}

.logos.wrapper .logo-wrapper{
    margin: 1%;
    height: 200px;
    width: 200px;
    overflow: hidden;
    padding: 0 0 40px 0;
    background-color: var(--white);
    border-radius: var(--main-border-radius);
}

.logos.wrapper .logo-wrapper .logo{
    padding: 10px;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.logos.wrapper .logo-wrapper .verein{
    font-weight: 800;
}

@media only screen and (max-width: 768px){
    .logos.wrapper .logo-wrapper{
        height: 120px;
        width: 120px;
    }
}
.steckbrief{
    width: 100%;
}

.steckbrief .steckbrief-wrapper{
    margin: 50px auto 0 auto;
    width: 90%;
    min-height: 300px;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    /* overflow: hidden; */
}

.steckbrief .steckbrief-wrapper-inner{
    overflow: hidden;
    border-radius: var(--main-border-radius);
    position: relative;
    width: 450px;
    height: auto;
    margin: 0 20px 20px 20px;
    box-shadow: var(--main-box-shadow);

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

@media only screen and (max-width: 768px){
    .steckbrief .steckbrief-wrapper-inner{
        width: 100%;
    }
}


.beiträge{
        text-align: center;
        color: var(--svss-blue);
    }
    .beiträge-wrapper{
        display: grid;
        grid-template-columns: repeat(3, 33%);
        grid-gap: 2rem 2rem;
        gap: 2rem 2rem;
        margin: 0 auto;
        width: 85%;

    }

@media only screen and (max-width: 1200px){
    .beiträge-wrapper{
        grid-template-columns: repeat(2, 50%);
        margin: 0 auto;
    }
}

@media only screen and (max-width: 768px){
    .beiträge-wrapper{
        grid-template-columns: 100%;
        margin: 0 auto;
    }
}

.mehr-laden{
    max-width: 50%;
    margin: 40px auto;
    text-align: center;
    color: var(--svss-blue);
    cursor: pointer;
    transition: var(--mainTransition);
  }

.mehr-laden:hover{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

.mehr-laden .icon{
    font-size: 2rem;
}

.mehr-laden p{
    margin: 0;
}
.suche-wrapper{
    margin: 0 auto 50px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.suche-wrapper form{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.suche-wrapper input{
    border-radius: var(--main-border-radius);
    /* line-height: 1rem; */
    width: 350px;
    height: 40px;
    padding: 0 0 0 10px;
    box-shadow: var(--main-box-shadow);

    border: 0px solid black;
    /* box-shadow: 0px 0px 5px 0px black; */
}
.suche-wrapper input:hover{
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
}

.suche-wrapper input,
.suche-wrapper input:focus{
    outline: none;
}

.suche-wrapper .btn-1{
    cursor: pointer;
}
.single-beitrag.text-wrapper{
    padding: 5%;
    color: var(--tertiary-color);
}

.single-beitrag.text-wrapper .inner-text-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;
    padding: 30px;
    border-top: 1px solid var(--tertiary-color);
    /* background-color: red; */
}

.beitrag-info.single{
    max-width: 500px;
    display: inline-block;
    width: 100%;
}

.beitrag-info__inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.beitrag-info__inner_ü{
    text-transform: uppercase;
    font-weight: 800;
}

.beitrag-link{
    color: var(--svss-blue);
}


@media only screen and (max-width: 768px){
    .single-beitrag.text-wrapper .inner-text-wrapper{
        flex-direction: column;
    }

    .beitrag-info__inner{
        flex-direction: column;
    }

    .beitrag-info__inner_ü{
        margin-top: 10px;
    }

    .single-beitrag.text-wrapper .btn-primary{
        margin-top: 40px;
    }
}
.timeline {
  margin: 40px;
  position: relative;
}

.timeline:before {
  background-color: var(--svss-blue);
  content: '';
  margin-left: -1px;
  position: absolute;
  top: 0;
  left: 2em;
  width: 2px;
  height: 100%;
}

.timeline .timeline-event {
  position: relative;
}
.timeline .timeline-event:hover .timeline-event-icon {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  background-color: var(--svss-blue);
}
/* .timeline .timeline-event:hover .timeline-event-thumbnail {
  -moz-box-shadow: inset 40em 0 0 0 var(--svss-blue);
  -webkit-box-shadow: inset 40em 0 0 0 var(--svss-blue);
  box-shadow: inset 40em 0 0 0 var(--svss-blue);
} */

.timeline .chronik-titel{
  text-transform: "none";
  color: var(--tertiary-color);
}

.timeline .timeline-event-copy {
  padding: 2em;
  position: relative;
  top: -1.875em;
  left: 4em;
  width: 80%;
}

.timeline .timeline-event-copy strong {
  font-weight: 700;
}

.timeline .timeline-event-copy p:not(.timeline-event-thumbnail) {
  padding-bottom: 1.2em;
}

.timeline .timeline-event-icon {
  transition: -webkit-transform 0.2s ease-in;
  transition: transform 0.2s ease-in;
  transition: transform 0.2s ease-in, -webkit-transform 0.2s ease-in;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: var(--svss-blue);
  outline: 10px solid var(--secondary-color);
  display: block;
  margin: 0.5em 0.5em 0.5em -0.5em;
  position: absolute;
  top: 0;
  left: 2em;
  width: 1em;
  height: 1em;
}

.timeline .timeline-event-thumbnail {
  transition: box-shadow 0.5s ease-in 0.1s;
  /* -webkit-transition-delay: 0.1s; */
  color: var(--secondary-color);

  background-color: var(--svss-blue);
  box-shadow: inset 0 0 0 0em #ffffff;
  display: inline-block;
  margin-bottom: 1.2em;
  padding: 0.25em 1em 0.2em 1em;
}

.timeline .foto-wrapper{
  height: 100px;
  width: 100%;
}

.timeline .foto-wrapper img{
  height: 100%;
  object-fit: contain;
}

.timeline .link{
  transition: var(--mainTransition);
  color: var(--teritary-color);
  -webkit-transform-origin: center;
          transform-origin: center;
}

.timeline .link:hover{
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  box-shadow: var(--main-box-shadow);
}


.btn.downloads {
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  
  min-width: 350px;
  width: 50%;

  text-transform: uppercase;
  outline: none;
  overflow: hidden;
  position: relative;
  color: var(--secondary-color);

  background-color: var(--svss-blue);
  padding: 5px 50px;
  margin: 10px;
  border-radius: var(--main-border-radius);
  box-shadow: var(--main-box-shadow);
  transition: var(--mainTransition);
}

.btn.downloads:hover{
  -webkit-transform: scale(1.01);
          transform: scale(1.01)
}

.btn.downloads span {
  position: relative; 
  z-index: 1;
}

/* .btn.downloads:hover span,
.btn.downloads:hover .icon{
  color: var(--svss-blue);
} */

/* .btn.downloads:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 1000%;
  width: 100%;
  background: var(--secondary-color);

  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  -webkit-transform: translateX(-98%) translateY(-25%) rotate(-45deg);
  transform: translateX(-98%) translateY(-25%) rotate(-45deg);
}

.btn.downloads:hover:after {
  -webkit-transform: translateX(-9%) translateY(-25%) rotate(-45deg);
  transform: translateX(-9%) translateY(-25%) rotate(-45deg);
} */

.downloads a{
  z-index: 1;
}
.downloads .icon {
  cursor: pointer;
  font-size: 2rem;
  transition: var(--mainTransition);
  color: var(--secondary-color);
}

.downloads .icon:hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}


.impressum-wrapper {
    color: var(--tertiary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    height: auto;
    width: 100%;
    margin: 0 auto;
    padding: 10% 10% 5% 10%;
    text-align: center;
}

.impressum-wrapper p {
        margin: .1rem;
}

.impressum-wrapper h4 {
    margin: .2rem;
    border-bottom: 1px solid var(--tertiary-color);
}
.datenschutz-wrapper{
    display: inline-block;
    color: var(--tertiary-color);
    height: auto;
    width: 100%;
    margin: 0 auto;
    padding: 10% 10% 5% 10%;
    text-align: center;
}
