@import url("../../Layout/index.css");
@import url("../../Layout/slider.css");

.archiv{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 100%;
}

.archiv-slider-image{
    height: 100%;
    width: 100%;
    object-fit: contain;
    background: var(--svss-blue);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: .5s all ease;
}

.archiv .archiv-wrapper{
    overflow: hidden;
    border-radius: var(--main-border-radius);
    position: relative;
    width: 90%;
    height: 50vw;
    box-shadow: var(--main-box-shadow);

    text-align: center;
    display: flex;
    justify-content: center;
}

.archiv-text{
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.archiv-text .lastSlide ,
.archiv-text .nextSlide {
    display: none;
  }

.activeSlide .slider-titel,
.activeslide .slider-description{
    text-align: center;
    color: var(--tertiary-color);
  }

