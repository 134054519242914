@import url("../Layout/index.css");

.social-media-wrapper{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding: 50px;
    /* background-color: red; */
}

.social-media-wrapper__inner{
    padding: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: auto;
}

.social-media-link{
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--main-border-radius);
}

.social-media-link:hover{
    transform: scale(1.03);
}

.social-media-wrapper__inner__inner{
    border: none;
    overflow: hidden;
    border-radius: var(--main-border-radius);
    box-shadow: var(--main-box-shadow);
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--secondary-color);
    position: relative;
}

.facebook{
    width: 500px;
    height: 705px;
}

.snapwidget-widget{
    width: 500px;
    height: 705px;
}


@media only screen and (max-width: 768px){
    .social-media-wrapper__inner{
        width: 100%;
        overflow: hidden;
    }

    .facebook{
        width: 350px;
        height: 705px;
        display: none;
    }
    
    .snapwidget-widget{
        width: 350px;
        height: 705px;
        display: none;
    }
}