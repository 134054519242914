@import "../Layout/index.css";

.kontakt.wrapper{
    display: flex;
    justify-content: center;
    align-items:center;
    flex-wrap: wrap;

    height: auto;
    width: 100%;
    margin: 0 auto;
    padding: 0 10%;
    text-align: center;
}

.kontakt .person{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin: 30px;
    padding: 10px;
    min-width: 330px;
    min-height: 110px;

    background-color: var(--white);
    border-radius: var(--main-border-radius);
    box-shadow: var(--main-box-shadow);
}

.kontakt .funktion{
    margin: 0;
}

@media only screen and (max-width: 768px){
    .kontakt{
        flex-direction: column;
    }
}

@media only screen and (max-width: 500px){
    .kontakt .person{
        width: 100%;
        min-width: 200px;
        margin: 30px 0;
    }
}

