@import "../Layout/index.css";

.impressum-wrapper {
    color: var(--tertiary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    height: auto;
    width: 100%;
    margin: 0 auto;
    padding: 10% 10% 5% 10%;
    text-align: center;
}

.impressum-wrapper p {
        margin: .1rem;
}

.impressum-wrapper h4 {
    margin: .2rem;
    border-bottom: 1px solid var(--tertiary-color);
}