@import url("../Layout/index.css");

.steckbrief{
    width: 100%;
}

.steckbrief .steckbrief-wrapper{
    margin: 50px auto 0 auto;
    width: 90%;
    min-height: 300px;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    /* overflow: hidden; */
}

.steckbrief .steckbrief-wrapper-inner{
    overflow: hidden;
    border-radius: var(--main-border-radius);
    position: relative;
    width: 450px;
    height: auto;
    margin: 0 20px 20px 20px;
    box-shadow: var(--main-box-shadow);

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

@media only screen and (max-width: 768px){
    .steckbrief .steckbrief-wrapper-inner{
        width: 100%;
    }
}

