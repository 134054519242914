@import url("./variables.css");
@import url("./slider.css");

*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* color: var(--tertiary-color); */
  font-family: SourceSansPro-ExtraLight; 
  font-size: 1em;
  transition: var(--mainTransition);
  box-sizing: border-box;
}


body{
  background-color: var(--secondary-color);
  width: 100%;
}

.wrapper{
  display: flex;
  justify-content: center;
  align-items:center;
  flex-wrap: wrap;

  height: auto;
  width: 100%;
  margin: 0 auto;
  padding: 0 10%;
  text-align: center;
}


@font-face{
  font-family: SourceSansPro-Bold;
  src: url('./fonts/SourceSansPro-Bold.otf') format('opentype');
}

@font-face{
  font-family: Gotham;
  src: url('./fonts/Gotham.otf');
}

@font-face{
  font-family: SourceSansPro-ExtraLight;
  src: url('./fonts/SourceSansPro-ExtraLight.otf');
}

.überschrift{
  font-family: SourceSansPro-Bold;
  text-transform: uppercase;
}

ul {
  list-style-type: none;
}
a,
a:link,
a:visited {
  text-decoration: none;
  /* color: var(--secondary-color); */
}

h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  /* text-transform: capitalize; */
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-5);
}

.content-wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.maps{
  width: 100%;
  height: 100%;
  transform: scale(1.02);
}

.maps-wrapper{
  margin: 2%;
  width: 45%;
  height: 400px;
  overflow: hidden;
  border-radius: var(--main-border-radius);
  box-shadow: var(--main-box-shadow);
}

/* Scrollbar -------------*/
::-webkit-scrollbar {
  width: 15px;
}
::-webkit-scrollbar-track {
  background: var(--secondary-color); 
}

::-webkit-scrollbar-thumb {
  background: var(--svss-blue); 
  border-radius: 10px;
}
/* Scrollbar end --------------*/


/* Buttons ------------ */
.btn-primary {
  text-transform: uppercase;
  letter-spacing: var(--mainSpacing);
  color: var(--svss-blue);
  border: 2px solid var(--svss-blue);
  padding: 0.2rem 0.8rem;
  transition: var(--mainTransition);
  cursor: pointer;
  font-size: 0.8rem;
  background: transparent;
  border-radius: var(--main-border-radius);
}

.btn-primary {
  background: var(--svss-blue);
  color: var(--white);
}

.btn-primary:hover {
  background: var(--secondary-color);
  color: var(--svss-blue);
}

.btn-block {
  width: 100%;
  display: block;
  margin: 0 auto;
  box-shadow: var(--lightShadow);
  text-align: center;
}
.btn-details {
  padding: 0.25rem 0.4rem;
}
.btn-details:hover {
  background: var(--primaryLightColor);
  border-color: var(--primaryLightColor);
}

.btn-1 {
  border: none;

  /* min-width: 350px; */
  text-align: left;

  text-transform: uppercase;
  outline: none;
  color: var(--secondary-color);

  background-color: var(--svss-blue);
  padding: 5px 20px;
  margin: 10px;
  border-radius: var(--main-border-radius);
  box-shadow: var(--main-box-shadow);
  transition: var(--mainTransition);
}

.btn-1:hover{
  transform: scale(1.03)
}

.btn-1 span {
  position: relative; 
}

@media only screen and (min-width: 2500px){
  body{
    margin: 0 auto;
    display: block;
    width: 2500px;
    border: solid 4px var(--svss-blue);

  }
}

/* Buttons end ----------------*/

@media only screen and (max-width: 1200px){
  .maps-wrapper{
    width: 100%;
  }
}

@media only screen and (max-width: 768px){
  .btn-1 {
    min-width: 0px;
    width: 100%;
    padding: 5px;
  }

  h1,
  h2,
  h3,
  h4 {
    line-height: 1.00;
    margin-bottom: 0.5rem;
  }
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.3rem;
  }
  h3 {
    font-size: .9rem;
  }
  h4 {
    font-size: .6rem;
  }
  p {
    margin-bottom: 1.25rem;
    color: var(--clr-grey-5);
  }

  
}
