@import url("../Layout/index.css");

    /* .beitrag{
        width: 100%;
        height: auto;
    } */

    .beitrag-wrapper{
        min-height: 400px;
        width: 100%;
        background: var(--white);
        box-shadow: var(--main-box-shadow);
        border-radius: var(--main-border-radius);
        transition: var(--mainTransition);
        /* overflow: hidden; */
    }

    .beitrag-wrapper:hover{
        transform: scale(1.03);
    }

    .beitrag-wrapper .image-wrapper{
        position: relative;
        background: var(--svss-blue);
        background-position: center center;
        background-repeat: no-repeat;
        height: 300px;
        width: 100%;
        border-radius: var(--main-border-radius) var(--main-border-radius) 0 0;
        overflow: hidden;
    }

    .beitrag-wrapper .text-wrapper{
        padding: 1rem;
        width: 100%;
        border-radius: 0 0 var(--main-border-radius) var(--main-border-radius);
        color: black;
        position: relative;
    }

    .beitrag-wrapper .image-wrapper img{
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: var(--mainTransition);
    }

    .beitrag-wrapper .image-wrapper img:hover{
        transform: scale(1.1);
    }

    .beitrag-wrapper .text-wrapper .datum{
        margin: 0 0 3rem 0;
        font-weight: 800;
    }

    .beitrag-wrapper .beitrag-info.beitrag{
        width: 100%;
    }

    .beitrag-wrapper .titel{
        min-height: 4rem;
    }

    .beitrag-wrapper .text{
        min-height: 7rem;
    }

