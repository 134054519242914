@import "../../Layout/index.css";

.dark-mode-button{
    display: flex;
    align-items: center;
    justify-content: center;

    width: 35px;
    height: 35px;
    border-radius: 50%;

    position: absolute;
    bottom: 20px;
    right: 20px;
    
    cursor: pointer;
    background-color: var(--secondary-color);
    transition: var(--mainTransition);
    text-transform: uppercase;
    font-weight: 1000;
    font-size: .8rem;
    box-shadow: var(--main-box-shadow);
}

.dark-mode-button:hover{
    transform: scale(1.05);
}

.dark-mode-button.dunkel {
    background-color: var(--white);
 }



.dark-mode-button .dunkel,
.dark-mode-button .hell {
    color: var(--tertiary-color);
 }

 /* .dark-mode-button .hell{
    color: var(--teritary-color);
 } */