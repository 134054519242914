@import "../Layout/index.css";

.jfg .text{
    color: var(--tertiary-color);
}
.logos.wrapper{
    padding: 5% 0;
}

.logos.wrapper .logo-wrapper{
    margin: 1%;
    height: 200px;
    width: 200px;
    overflow: hidden;
    padding: 0 0 40px 0;
    background-color: var(--white);
    border-radius: var(--main-border-radius);
}

.logos.wrapper .logo-wrapper .logo{
    padding: 10px;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.logos.wrapper .logo-wrapper .verein{
    font-weight: 800;
}

@media only screen and (max-width: 768px){
    .logos.wrapper .logo-wrapper{
        height: 120px;
        width: 120px;
    }
}