@import url("../../Layout/index.css");

.navbar{
    height: 100vh;
    position: relative;
    overflow: hidden;
    position:relative;
}

.navbar::after{
    width: 100%;
    height: 2px;
    background-color: var(--svss-blue);
    z-index:-100;
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
}

.navbar__leiste{
    height: 80px;
    width: 100%;
    background: var(--svss-blue);
}

.navbar .svss-logo-wrapper{
    cursor: pointer;
    position: absolute;
    width: 90px;
    height: 90px;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 35px;
    transition: all .5s ease;
    border-radius: 50%;
    background-color: var(--white);
}

.navbar .svss-logo-wrapper:hover{
    transform: scale(1.05);
}

.navbar .svss-logo{
    width: 100%;
    height: 100%;
    transform: scale(0.75);
    object-fit: contain;
}

.nav-alt-img-wrapper{
    width: 100%;
    height: 100%;
    border-bottom: 5px solid var(--svss-blue);
    z-index: -50;
}

.nav-alt-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hamburger-left{
    position: absolute;
    top: 28px;
    left: 28px;
}

.hamburger-right{
    position: absolute;
    top: 28px;
    right: 28px;
}

.page-name{
    color: var(--white);
    position: absolute;
    top: 20px;
    left: 100px;
    line-height: 100%;
    font-family: SourceSansPro-Bold;
    font-size: 2.5em;
    text-transform: uppercase;
}


.small-window{
    display:none;
    transform: scale(.7);
    z-index: 100;
  }

  @media (max-aspect-ratio: 3/4){
    .navbar{
        height: 50vh;
    }
  }

@media only screen and (max-width: 768px){

    .navbar .svss-logo-wrapper{
        top: -5px;
        left: auto;
        right: 0px;
        transition: all .5s ease;
        transform: scale(.6);
    }

    .navbar .svss-logo-wrapper:hover{
        transform: scale(.7);
    }

    .page-name{
        top: 29px;
        left: 70px;
        font-size: 1.5rem;
    }

    .normal-window{
        display:none;
      }

    .small-window{
        display:block;
    }
  }

