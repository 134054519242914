@import url("../../Layout/index.css");


.sponsoren-logo{
    width: 100%;
    height: 100%;
    object-fit:contain;

}

.sponsoren-logo-wrapper:hover{
    transform: scale(1.05);
}

.sponsoren-logo-wrapper{
    transition: all 1s ease;
    width: 100px;
    height: 70px;
    overflow: hidden;
    margin: 10px;
    background-color: #ffffff;
    vertical-align: center;
    border: solid 1px var(--secondary-color);
    border-radius: 5px;
}

.sponsoren-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;

    margin: 0 auto;
    height: auto;
    width: 80%;
}


/* @media only screen and (max-width: 1200px){
.sponsoren-wrapper{
    grid-template-columns: repeat(2, 50%);
    margin: 0 auto;
}
}

@media only screen and (max-width: 768px){
.sponsoren-wrapper{
    grid-template-columns: 100%;
    margin: 0 auto;
}
} */