@import url("../Layout/variables.css");


    .beiträge{
        text-align: center;
        color: var(--svss-blue);
    }
    .beiträge-wrapper{
        display: grid;
        grid-template-columns: repeat(3, 33%);
        gap: 2rem 2rem;
        margin: 0 auto;
        width: 85%;

    }

@media only screen and (max-width: 1200px){
    .beiträge-wrapper{
        grid-template-columns: repeat(2, 50%);
        margin: 0 auto;
    }
}

@media only screen and (max-width: 768px){
    .beiträge-wrapper{
        grid-template-columns: 100%;
        margin: 0 auto;
    }
}