@import url("../Layout/index.css");

.verein{
    width: 100%;
}

.verein .verein-grid{
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-template-rows: auto;
    gap: 50px;
    grid-template-areas: 
        "logo text"
        "logo website";
    align-items: center;
    /* justify-items: center; */
    width: 80%;
    margin: 0 auto;
}

.verein .logo{
    grid-area: logo;
    overflow:hidden;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--main-border-radius);
}

.verein .logo img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.verein .text{
    grid-area: text;
    width: 100%;
    text-transform: none;
    color: var(--tertiary-color);
}

.verein .website{
    grid-area: website;
    width: 100%;
}

.website:hover{
    transform: scale(1.01)
  }

.verein .verein-wrapper{
    margin: 50px auto 0 auto;
    width: 90%;
    min-height: 300px;
    justify-content: space-around;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.verein .verein-wrapper-inner{
    overflow: hidden;
    border-radius: var(--main-border-radius);
    position: relative;
    width: 45%;
    height: 25vw;
    margin: 20px;
    box-shadow: var(--main-box-shadow);

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media only screen and (max-width: 1200px){
    .verein .verein-wrapper{
        display: flex;
        flex-direction: column;
    }
    
    .verein .verein-wrapper-inner{
        width: 100%;
    }
}

@media only screen and (max-width: 768px){
    .verein .verein-grid{
        grid-template-columns: 1fr;
        grid-template-rows: 150px auto auto;
        gap: 10px;
        grid-template-areas: 
            "logo"
            "text"
            "website";
        align-items: center;
        width: 90%;
        margin: 0 auto;
    }
    
    .verein .logo{
        grid-area:logo;
        /* background-color: red; */
    }

    .verein .logo img {
       height: 100%;
       width: 100%;
       object-fit: contain;
    }
    
    .verein .text{
        grid-area: text;

    }
    
    .verein .website{
        grid-area: website;
    }

    .verein .verein-wrapper-inner{
        height: 60vw;
        width: 95%;
    }
    
}

