@import "../Layout/index.css";

.datenschutz-wrapper{
    display: inline-block;
    color: var(--tertiary-color);
    height: auto;
    width: 100%;
    margin: 0 auto;
    padding: 10% 10% 5% 10%;
    text-align: center;
}