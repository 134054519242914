@import "../Layout/index.css";


.timeline {
  margin: 40px;
  position: relative;
}

.timeline:before {
  background-color: var(--svss-blue);
  content: '';
  margin-left: -1px;
  position: absolute;
  top: 0;
  left: 2em;
  width: 2px;
  height: 100%;
}

.timeline .timeline-event {
  position: relative;
}
.timeline .timeline-event:hover .timeline-event-icon {
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  background-color: var(--svss-blue);
}
/* .timeline .timeline-event:hover .timeline-event-thumbnail {
  -moz-box-shadow: inset 40em 0 0 0 var(--svss-blue);
  -webkit-box-shadow: inset 40em 0 0 0 var(--svss-blue);
  box-shadow: inset 40em 0 0 0 var(--svss-blue);
} */

.timeline .chronik-titel{
  text-transform: "none";
  color: var(--tertiary-color);
}

.timeline .timeline-event-copy {
  padding: 2em;
  position: relative;
  top: -1.875em;
  left: 4em;
  width: 80%;
}

.timeline .timeline-event-copy strong {
  font-weight: 700;
}

.timeline .timeline-event-copy p:not(.timeline-event-thumbnail) {
  padding-bottom: 1.2em;
}

.timeline .timeline-event-icon {
  -moz-transition: -moz-transform 0.2s ease-in;
  -o-transition: -o-transform 0.2s ease-in;
  -webkit-transition: -webkit-transform 0.2s ease-in;
  transition: transform 0.2s ease-in;
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: var(--svss-blue);
  outline: 10px solid var(--secondary-color);
  display: block;
  margin: 0.5em 0.5em 0.5em -0.5em;
  position: absolute;
  top: 0;
  left: 2em;
  width: 1em;
  height: 1em;
}

.timeline .timeline-event-thumbnail {
  -moz-transition: box-shadow 0.5s ease-in 0.1s;
  -o-transition: box-shadow 0.5s ease-in 0.1s;
  -webkit-transition: box-shadow 0.5s ease-in;
  transition: box-shadow 0.5s ease-in 0.1s;
  /* -webkit-transition-delay: 0.1s; */
  color: var(--secondary-color);

  background-color: var(--svss-blue);
  -moz-box-shadow: inset 0 0 0 0em #ffffff;
  -webkit-box-shadow: inset 0 0 0 0em #ffffff;
  box-shadow: inset 0 0 0 0em #ffffff;
  display: inline-block;
  margin-bottom: 1.2em;
  padding: 0.25em 1em 0.2em 1em;
}

.timeline .foto-wrapper{
  height: 100px;
  width: 100%;
}

.timeline .foto-wrapper img{
  height: 100%;
  object-fit: contain;
}

.timeline .link{
  transition: var(--mainTransition);
  color: var(--teritary-color);
  transform-origin: center;
}

.timeline .link:hover{
  transform: scale(1.1);
  box-shadow: var(--main-box-shadow);
}